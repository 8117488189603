<template>
    <div class="ui-main">
        <a-spin :spinning="loading" size="large">
            <a-form ref="formRef" :model="modelRef" @finish="onSubmit">
                <a-form-item v-bind="formItemLayout" label="是否父级">
                    <a-switch v-model:checked="modelRef.isParent" />
                </a-form-item>

                <a-form-item name="titleDecorator" v-bind="formItemLayout" label="标题" required :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="modelRef.titleDecorator" />
                </a-form-item>

                <a-form-item name="descriptionDecorator" v-bind="formItemLayout" label="描述" required :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input v-model:value="modelRef.descriptionDecorator" />
                </a-form-item>

                <a-form-item name="url" v-bind="formItemLayout" label="唯一标识" required :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-input
                            v-model:value="modelRef.url"
                            placeholder="例如: menu_edit，需与路由的permission值保持一致"
                    />
                </a-form-item>

                <a-form-item name="sortDecorator" v-bind="formItemLayout" label="排序" extra="(数字越大越靠前)">
                    <a-input v-model:value="modelRef.sortDecorator" />
                </a-form-item>

                <div v-if="!modelRef.isParent">
                    <a-form-item name="parentUrl" v-bind="formItemLayout" label="父级列表" required :rules="[{required: true, message: '必选项不允许为空'}]">
                        <a-select
                                v-model:value="modelRef.parentUrl"
                                placeholder="请选择"
                                @change="onParentChange"
                        >
                            <a-select-option v-for="menuItem in menus" :key="menuItem.url" :value="menuItem.url" :title="menuItem.description">{{menuItem.title}}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item name="parentTwoUrl" v-bind="formItemLayout" label="第二父级列表">
                        <a-select
                                v-model:value="modelRef.parentTwoUrl"
                                placeholder="请选择"
								@change="onParentTwoChange"
                        >
                            <a-select-option v-for="menuItem in menusTwo" :key="menuItem.url" :value="menuItem.url" :title="menuItem.description">{{menuItem.title}}</a-select-option>
                        </a-select>
                    </a-form-item>
					
					<a-form-item name="parentThirdUrl" v-bind="formItemLayout" label="第三父级列表">
					    <a-select
					            v-model:value="modelRef.parentThirdUrl"
					            placeholder="请选择"
					    >
					        <a-select-option v-for="menuItem in menusThird" :key="menuItem.url" :value="menuItem.url" :title="menuItem.description">{{menuItem.title}}</a-select-option>
					    </a-select>
					</a-form-item>
                </div>

                <a-form-item
                        :wrapper-col="{ span: 12, offset: 6 }"
                >
                    <a-button type="primary" html-type="submit" :loading="loading">提交</a-button>
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
    import * as systemApi from '@/service/modules/system';
    export default {
        props: {
            isEdit: {      // true :编辑  false: 新增
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                id: 0,
                modelRef: {
                    isParent: false,
                    titleDecorator: '',
                    descriptionDecorator: '',
                    sortDecorator: 0,
                    url: '',
                    parentUrl: undefined,
                    parentTwoUrl: undefined
                },
                form: null,
                isParent: 0,
                formItemLayout: {
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14 },
                },
                menus: [],
                menusTwo: [],
				menusThird: []
            }
        },
        created() {
            this.formatMenu();  // 一级菜单过滤
            if (this.isEdit && this.$route.query.id) {
                this.id = this.$route.query.id;
                this.getData();
            }
        },
        methods: {
            onSubmit() {
                let postData = {
                    isParent: this.modelRef.isParent ? 1 : 0,
                    title: this.modelRef.titleDecorator,
                    description: this.modelRef.descriptionDecorator,
                    url: this.modelRef.url,
                    parents: this.modelRef.parentTwoUrl ? this.modelRef.parentTwoUrl : this.modelRef.parentUrl
                };
				if(this.modelRef.parentThirdUrl) {
					postData.parents = this.modelRef.parentThirdUrl;
				}
                if (this.isEdit) {
                    postData.id = this.id;
                    systemApi.editMenu(postData).then(res => {
                        if (res.code == 200){
                            this.$message.success('操作成功');
                            this.$closePage(this.$router.currentRoute.value, '/system/systemRoot/menu/view');
                        }
                    })
                } else {
                    systemApi.addMenu(postData).then(res => {
                        if (res.code == 200){
                            this.$message.success('操作成功');
                            this.$closePage(this.$router.currentRoute.value, '/system/systemRoot/menu/view');
                        }
                    }).catch(() => {})
                }
            },
            getData() {
                this.loading = true;
                systemApi.menuDetail({
                    id: this.id
                }).then(res => {
                    this.loading = false;
                    if (res.code == 200) {
                        let parent = res.data.routing.split(',') || [];
                        this.modelRef = {
                            isParent: res.data.isParent ? true : false,
                            titleDecorator: res.data.title,
                            descriptionDecorator: res.data.description,
                            sortDecorator: res.data.sort,
                            url: res.data.url,
                            parentUrl: parent.length > 1 ? parent[0] : undefined,
                            parentTwoUrl: parent.length > 2 ? parent[1] : undefined,
							parentThirdUrl: parent.length > 3 ? parent[2] : undefined,
                        }
                        if (this.modelRef.parentUrl) {
                            this.onParentChange(this.modelRef.parentUrl, true);
                        }
                    }
                })
            },
            formatMenu() {
                this.menus = this.$store.state.system.menuList.filter(item => {
                    return item.isParent === 1
                });
            },
            onParentChange(url, isDetail) {
                if (!isDetail) {
                    this.modelRef.parentTwoUrl = undefined;
                    this.menusTwo = [];
                }
                systemApi.getMenuByParents({parents: url}).then(res => {
                    if (res.code == 200) {
                        this.menusTwo = res.data;
						if (this.modelRef.parentTwoUrl) {
							this.onParentTwoChange(this.modelRef.parentTwoUrl, true);
						}
                    }
                })
            },
			onParentTwoChange(url, isDetail) {
				if (!isDetail) {
				    this.modelRef.parentTwoUrl = undefined;
				    this.menusThird = [];
				}
				systemApi.getMenuByParents({parents: url}).then(res => {
				    if (res.code == 200) {
				        this.menusThird = res.data;
				    }
				})
			}
        }
    }
</script>

<style scoped>
    .ui-main {
        width: 700px;
        text-align: left;
    }
</style>